<template lang="pug">
	main#pagina(ref="pagina")
		BannerGeral(:contents="bannerContent", v-if="!$store.state.loading")
		TimelineInstitucional(v-if="!$store.state.loading")
</template>

<script>
import pagina from '@/mixins/pagina'

export default {
  name: 'view-institucional',
  mixins: [pagina],
  computed: {
    bannerContent () {
      return { content: { title: { pt: 'O IPPUC' } } }
    }
  },
  metaInfo () {
    return {
      title: 'Institucional'
    }
  }
}
</script>

<style lang="stylus" scoped src="./Institucional.styl"></style>
